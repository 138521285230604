.wrapper {
    background-color: var(--croonus-lightGray);
    padding: var(--gap-half) var(--gap);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
}

.presentationControl {
    display: flex;
    gap: var(--gap);

    > * {
        width: 100%;
        display: flex;
        align-items: center;

        select {
            cursor: pointer;
        }

        &::before {
            content: "";
            background-color: black;
            height: 60%;
            width: 1px;
            position: absolute;
            left: 0;
            top: 20%;
        }
    }
}

.title {
    font-weight: 600;
}

.number {
    display: none;
}

.wrapper-sortList {
    position: absolute;
    padding: 0.5rem 1rem;
    background-color: var(--croonus-lightGray);
}

.select {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 0.8rem;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: none;
    font-weight: 450;
    cursor: pointer;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.iconButton {
    width: 25px;
    padding: 5px;
    height: 25px;
    background-color: transparent;
    border: unset;
    display: flex;

    img {
        width: 100%;
        height: 100%;
    }
}

.inactiveIcon {
    height: 25px;
    width: 25px;
    border-radius: 3px;
    margin-right: 0.5rem;
    background-color: transparent;
}

.activeIcon,
.inactiveIcon:hover {
    height: 25px;
    width: 25px;
    border-radius: 3px;
    margin-right: 0.5rem;
    background-color: var(--primary-color);

    img {
        filter: brightness(0) invert(1);
    }
}

.filters {
    display: none;
    width: 100%;
}

.filtersButton {
    display: block;
    width: 100%;
    font-size: 0.8rem;
    background-color: var(--croonus-black);
    color: var(--croonus-white);
}

@media screen and (max-width: 640px) {
    .select {
        width: 100%;
        height: 40px;
        align-self: center;
        padding: 0.5rem 1rem !important;
        background-color: var(--croonus-black);
        color: var(--croonus-white);
    }
}

@media only screen and (max-width: 992px) {
    .number {
        display: inline;
        font-size: 0.8rem;
        margin-bottom: var(--gap);
    }

    .select {
        width: 100%;
        padding: 0.5rem 1rem !important;
        background-color: var(--croonus-black);
        color: var(--croonus-white);
    }

    .filtersButton {
        width: 100%;
        display: block;
        padding: 0.5rem 1rem;
    }

    .wrapper {
        flex-direction: column;
        background-color: transparent;
        padding: 0;
    }
    .presentationControl {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, calc(50% - var(--gap-half)));
        gap: var(--gap);
    }
    .control {
        align-items: center;

        align-self: stretch;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 800;
    }
    .filters {
        display: flex;
    }
}

@media (max-width: 768px) {
    .iconButton,
    .activeIcon,
    .inactiveIcon {
        display: none !important;
    }
}
