.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 30px auto 30px;
    background-color: white;
    border-radius: 5px;
    height: 40px;
    outline: 1px solid var(--border-color);
    position: relative;
    min-width: 80px;

    > i {
        display: none;
        position: absolute;
        left: 50%;
        top: 40%;
        translate: -50% 0;
        color: var(--croonus-inactive);
    }

    &.dirty {
        background-color: #ffe6e6;
        color: #ccc;
        > i {
            display: block;
        }
        input{
            color: #ccc;
        }
    }

    .button {
        font-size: 1.35rem;
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        font-weight: 400;
        padding: 0 0.5rem;
        cursor: pointer;

        &:active {
            background-color: var(--croonus-lightGray);
            border: 0 solid var(--border-color);
        }
    }

    > input {
        width: 100%;
        text-align: center;
        padding: 0;
        font-size: 0.8rem;
        background-color: transparent;
        line-height: auto;
    }
}

@media only screen and (max-width: 992px) {
    .wrapper {
        width: 100%;
    }
}
